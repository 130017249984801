































































































































































































::v-deep .set-container {
    margin: 20px;
    background: #fff;
    height: calc(100% - 40px);
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    & > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.big-title {
    margin: 20px 20px 0;
    font-size: 20px;
    color: #000;
    font-weight: bold;
    line-height: 1;
}
.list-box {
    display: flex;
    justify-content: space-between;
    margin: 20px 20px 0;
    .item {
        width: 1%;
        flex: 1;
        border: 1px solid #F2F2F2;
        border-radius: 4px;
        padding: 20px;
        display: flex;
        align-items: center;
        .left {
            width: 1%;
            flex: 1;
            .text {
                color: #999;
            }
            .num {
                color: #409eff;
                font-size: 24px;
                font-weight: bold;
                margin-top: 6px;
            }
        }
        .iconfont {
            font-size: 124px;
            transform: rotate(30deg);
            color: #409eff;
            opacity: .08;
        }
    }
    .item + .item {
        margin-left: 20px;
    }
}
.no-data {
    height: 394px;
    margin-top: 10px;
    background: #f1f7ff;
    border: 1px solid #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    font-size: 18px;
}
.scroll-box {
    display: flex;
    margin: 20px 20px 0;
    .scroll-item {
        width: 1%;
        flex: 1;
        .scroll-title {
            font-size: 16px;
            color: #000;
        }
        .scroll-content {
            height: 394px;
            overflow: hidden;
            margin-top: 10px;
            background: #f1f7ff;
            padding: 0 20px 10px;
            border: 1px solid #F2F2F2;
        }
    }
    .scroll-item + .scroll-item {
        margin-left: 20px;
    }
    .send-item {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        padding: 20px;
        border: 1px solid #F2F2F2;
        border-radius: 4px;
        margin-top: 10px;
        .left {
            margin-right: 40px;
            text-align: center;
            .text {
                font-size: 18px;
            }
            .name {
                color: #666;
            }
        }
        .right {
            margin-left: 40px;
        }
        .center {
            position: relative;
            .text {
                position: absolute;
                left: 0;
                top: -10px;
                font-size: 12px;
                color: #666;
            }
        }
    }
    .receive-item {
        display: flex;
        align-items: center;
        background: #fff;
        padding: 0 24px;
        height: 69px;
        box-sizing: border-box;
        border: 1px solid #F2F2F2;
        border-radius: 4px;
        margin-top: 10px;
        .left {
            width: 1%;
            flex: 1;
            display: flex;
            .phone {
                margin: 0 30px;
            }
        }
        .right {
            margin-left: 20px;
        }
    }
    .good-item {
        display: flex;
        align-items: center;
        background: #fff;
        padding: 13px 20px;
        border: 1px solid #F2F2F2;
        border-radius: 4px;
        margin-top: 10px;
        .left {
            width: 60px;
            height: 60px;
            overflow: hidden;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .center {
            width: 1%;
            flex: 1;
            margin-left: 20px;
            .price {
                color: #666;
                margin-top: 10px;
            }
        }
    }
}
.red {
    color: #FF2400;
    margin-right: 10px;
}
